var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab_container"},[_c('div',{staticClass:"solution_part"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('h4',{staticClass:"mar-24"},[_vm._v(_vm._s(_vm.subTitle))]),_c('div',{staticClass:"tab_warp"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:{ tab_item: true, check: _vm.current == index },on:{"click":function($event){_vm.current = index}}},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 0),expression:"current == 0"}],staticClass:"content"},[_vm._m(0),_vm._m(1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 1),expression:"current == 1"}],staticClass:"content"},[_vm._m(2),_vm._m(3)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 2),expression:"current == 2"}],staticClass:"content"},[_vm._m(4),_vm._m(5)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.current == 3),expression:"current == 3"}],staticClass:"content"},[_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("纵观全局，辅助决策")]),_c('p',[_vm._v(" 覆盖企业汇报、数据看板、指挥中心等场景，打造多维度、立体化移动管理驾驶舱，助力企业经营决策。 ")]),_c('h3',[_vm._v("深层次数据下钻与分析")]),_c('p',[_vm._v(" 支持不限层次的数据钻取和多维度联动分析，协助用户更好地分析问题。 ")]),_c('h3',[_vm._v("融合多种数据源，快速构建数据中心")]),_c('p',[_vm._v(" 满足各类数据分析需求，实现炫酷直观的演示效果。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp2"},[_c('img',{attrs:{"src":require("@/assets/image/ProductApartment/bitab-1.png"),"width":"100%","alt":"","srcset":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("业、财、稅一体化")]),_c('p',[_vm._v(" 打通财务、业务、税务及资金流转之间的壁垒，实现企业智能化财务管理 ")]),_c('h3',[_vm._v("丰富的财务分析模型")]),_c('p',[_vm._v(" 资金分析、运营能力分析、盈利能力分析、偿债能力分析等财务分析，借助BI可帮助财务人员快捷应用各种分析模型。 ")]),_c('h3',[_vm._v("自定义更新报表数据")]),_c('p',[_vm._v(" 通过设置数据报表更新任务，让报表数据按需自定义更新，也可手动更新。避免重复性的数据拼接、汇总等繁琐工作。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/bitab-2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("提高市场营销和销售效率")]),_c('p',[_vm._v(" 企业可以更好地了解客户需求和市场趋势，制定更精确的销售计划和营销策略，提高市场营销和销售效率。 ")]),_c('h3',[_vm._v("培养数据驱动业务决策")]),_c('p',[_vm._v(" 以准确、高效的方式为员工提供数据分析服务，促进科学决策和公司的可持续发展。 ")]),_c('h3',[_vm._v("提高客户满意度和忠诚度")]),_c('p',[_vm._v(" 通过BI工具，企业可以更好地了解客户需求，提供更准确的产品和服务，从而提高客户满意度和忠诚度。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/bitab-3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit_text"},[_c('h3',[_vm._v("拖拉拽式快速制作BI报表")]),_c('p',[_vm._v("不用下载安装，直接通过浏览器打开就能用，通过拖拉拽方式快速搭建可视化分析报表。")]),_c('h3',[_vm._v("由“表”及里，实现数据智能")]),_c('p',[_vm._v(" 以“AI平民化”为理念，集成多种算法，可全流程可视化建模，降低AI应用门槛，驱动业务增长。 ")]),_c('h3',[_vm._v("丰富的图表展现，让人眼前一亮")]),_c('p',[_vm._v(" 精美的可视化展示，20多种图表形式，精心搭配的配色方案，找到最符合逻辑的表现方式。 ")]),_c('a',{attrs:{"href":"https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s","target":"_blank"}},[_c('div',{staticClass:"button btn-size"},[_vm._v("立即体验")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-warp"},[_c('img',{attrs:{"src":require("../../assets/image/ProductApartment/bitab-4.png"),"alt":""}})])
}]

export { render, staticRenderFns }