<template>
  <div>
    <div class="bg_wrap">
      <h3>赋能业务决策</h3>
      <h1>智能化BI工具释放企业数据潜力</h1>
      <h4>
        帮助企业快速搭建面向全员的大数据分析平台，让每一个成员都能充分了解并利用他们的数据，辅助决策、提升业务。
      </h4>
      <div class="btn_warp">
        <a-space size="large">
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">预约演示</div>
          </a>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button plain btn-size">免费试用</div>
          </a>

          <!-- <a-button type="primary" block size="large">预约演示</a-button>
          <a-button type="primary" block size="large" ghost>免费试用</a-button> -->
        </a-space>
      </div>
    </div>
    <!-- <div class="solution_part">
      <h2>产品价值</h2>
      <h4>面向全行业的资产管理系统，帮助企业提升资产运营效率</h4>
      <a-row :gutter="[24, { xs: 12, sm: 12, md: 24 }]">
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon1.png" alt="" />
            <div class="item_left">
              <h4>助力组织提效</h4>
              <p>
                让“人”更灵活地连接事、管理
                事，让各类信息、数据无界流通，从而实现组织效率提升。
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon2.png" alt="" />
            <div class="item_left">
              <h4>沉淀资产价值</h4>
              <p>
                充分了解自持资产的实时运营情况，以真实数据作为依托，做出最合理的决策，提高资产价值。
              </p>
            </div>
          </div>
        </a-col>
        <a-col :span="8" :md="8" :sm="24" :xs="24">
          <div class="product_item">
            <img src="../assets/image/ProductApartment/icon3.png" alt="" />
            <div class="item_left">
              <h4>科学赋能决策</h4>
              <p>
                基层用数据发现问题、中层用数据发现规律、高层用数据发现机会、决策层用数据发现趋势。
              </p>
            </div>
          </div>
        </a-col>
      </a-row>
    </div> -->
    <div class="solution_part">
      <h2>一站式大数据BI平台</h2>
      <h4 class="mar-24">
        全面覆盖数据分析过程中的各个环节，一个平台实现数据应用
      </h4>
      <a-row type="flex" class="bi-platform martop-45">
        <a-col>
          <div class="flat bi-pic1"></div>
        </a-col>
        <a-col class="bi-text-warp">
          <h3>全局数据总览实时把握业务进展</h3>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>打破信息壁垒，实时掌握数据变化</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>多种可视化图表展示，提供全方位的数据分析报告</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>可视化控件，自定义样式和布局</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>数据收集、整合、分析于一体，辅助企业更好的营销和决策</span>
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </a-col>
      </a-row>
      <a-row type="flex" class="bi-platform">
        <a-col class="bi-text-warp">
          <h3>实时数据统计自动化数据报表</h3>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>实时数据获取，全量获取不间断</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>数据报表自动生成，终结人工操作才来的误差，提升分析效率</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>支持多种形态数据呈现，结果一目了然</span>
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </a-col>
        <a-col>
          <div class="flat bi-pic2"></div>
        </a-col>
      </a-row>
      <a-row type="flex" class="bi-platform">
        <a-col>
          <div class="flat bi-pic3"></div>
        </a-col>
        <a-col class="bi-text-warp">
          <h3>多样化数据获取</h3>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>自定义数据报表，满足不同业务需求</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>可邮件订阅报表，简化数据报表的获取路径</span>
          </div>
          <div class="bi-item">
            <img
              src="../assets/image/ProductApartment/tips.png"
              class="hint"
              alt=""
            />
            <span>通过鼠标拖拽完成报表分组，便于分类管理</span>
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button btn-size">立即体验</div>
          </a>
        </a-col>
      </a-row>
    </div>
    <bi-apartment
      :title="solution.title"
      :subTitle="solution.subTitle"
    ></bi-apartment>
    <div class="solution_part">
      <h2>企业数据应用能力</h2>
      <h4 class="mar-24">
        轻松完成全流程数据分析任务，降低实施、集成、培训的成本
      </h4>
      <div class="serve_warp">
        <div class="serve_item" v-for="(item, index) in serveList" :key="index">
          <img :src="item.icon" alt="" />
          <h4>{{ item.title }}</h4>
          <p>{{ item.text }}</p>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即体验</div>
      </a>
    </div>
    <div class="solution_part bottom_warp">
      <h2>更懂企业的管理痛点，陪伴客户走向成功</h2>
      <h4>专家团队实践经验丰富，提供一站式咨询服务</h4>
    </div>
    <div class="bottom_bg">
      <div class="exhibition_warp">
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom1.png" alt="" />
          <div class="exhibition_text">
            <h4>售前咨询</h4>
            <p>
              资深的行业方案专家
              <br />
              根据您的诉求设计解决方案
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom2.png" alt="" />
          <div class="exhibition_text">
            <h4>实施上线</h4>
            <p>
              专业的实施团队，根据企业的业务场景
              <br />
              进行安装部署与系统配置
            </p>
          </div>
        </div>
        <div class="exhibition_item">
          <img src="../assets/image/ProductApartment/bottom3.png" alt="" />
          <div class="exhibition_text">
            <h4>售后及运营</h4>
            <p>
              可靠的售后及运营团队，提供使用培训与
              <br />
              案例分享，持续为企业迭代产品和服务
            </p>
          </div>
        </div>
      </div>
      <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
        <div class="button btn-size exper">立即咨询</div>
      </a>
    </div>
    <!-- <div class="container">
      <div class="title_con">
        <h1>技术实施解决方案</h1>
        <h4>根据需求自定义采购，全面助力长短租公寓企业信息化升级</h4>
      </div>
      <solution-component class="pc_solution"></solution-component>
    </div>
    <div class="mobile_solution">
      <div class="item">
        <img src="../assets/image/homebg_1.png" alt="" />
        <h3>云部署解决方案</h3>
        <p>
          依托强大的云计算能力，为企业提供一站式服务，无需下载安装，无需适配不同的软硬件平台，即可轻松使用标准版系统，实现高效管理。适用于中型公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_3.png" alt="" />
        <h3>私有化解决方案</h3>
        <p>
          依托企业自有平台，根据企业需求将其部署到指定的云服务器，包括管理系统、官网、官微、小程序等，形成自己的数据库，实现特色化管理。适用于中大型公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_5.png" alt="" />
        <h3>轻定制解决方案</h3>
        <p>
          基于私有化解决方案增加定制服务，根据企业需求可对数据报表、支付系统、智能硬件等模块进行单独定制，适用于有清晰业务模式的品牌公寓企业。
        </p>
      </div>
      <div class="item">
        <img src="../assets/image/homebg_7.png" alt="" />
        <h3>全定制解决方案</h3>
        <p>
          组建行业资深专家组，深入企业内部全面了解业务场景，针对企业自身的业务痛点，打造专属化的解决方案，打造成企业特有的品牌。适用于大型或集团型企业。
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import biApartment from "../components/product/bi-apartment.vue";
// import SolutionItem from "../components/SolutionItem";
export default {
  name: "Solution",
  components: {
    biApartment,
  },
  data() {
    return {
      solution: {
        title: "人人都能分析数据提升业务",
        subTitle:
          "BI帮助企业快速搭建面向全员的数据分析及BI平台让每一个成员都能充分了解并利用他们的数据，辅助决策、提升业务",
      },
      // current: 0,
      // tabList: [
      //   "管理人员的驾驶舱",
      //   "运营人员的工作智襄",
      //   "财务人员的贴心助理",
      //   "租客服务的观测台",
      // ],
      serveList: [
        {
          icon: require("../assets/image/ProductApartment/serve1.png"),
          title: "广泛的数据源接口",
          text: "支持40多种数据源添加及本地数据导入，避免重复输入数据库连接信息",
        },
        {
          icon: require("../assets/image/ProductApartment/serve2.png"),
          title: "高效创建数据集",
          text: "全面的数据治理手段，提高数据质量，查看数据使用流向",
        },
        {
          icon: require("../assets/image/ProductApartment/serve3.png"),
          title: "高性能计算",
          text: "拥有列存储、分布式计算、内存计算、分布式通讯等技术，实现亿级数据秒级运算",
        },
        {
          icon: require("../assets/image/ProductApartment/serve4.png"),
          title: "自助式探索分析",
          text: "过滤数据、多维钻取分析、数据高亮分析、趋势分析等",
        },
        {
          icon: require("../assets/image/ProductApartment/serve5.png"),
          title: "可视化数据报告",
          text: "丰富的可视化组件样式，包括数十种图及中国式复杂报表",
        },
        {
          icon: require("../assets/image/ProductApartment/serve6.png"),
          title: "AI深度分析",
          text: "与BI数据可视化深度融合实现联动分析，让AI真正走进业务，实现数据智能",
        },
        {
          icon: require("../assets/image/ProductApartment/serve7.png"),
          title: "可靠的企业级管理",
          text: "具有日志管理、认证授权、资源部署、权限管理等完整的企业级管理能力",
        },
        {
          icon: require("../assets/image/ProductApartment/serve8.png"),
          title: "便捷的移动跨屏",
          text: "无缝支持PC、手机、平板，并保持一致、易用的用户体验",
        },
      ],
    };
  },
  created() {
    // this.$store.commit('changeOpacity',false)
  },
  methods: {
    lookDetails(name) {
      this.$router.push({ name: "solutionDetails", params: { name: name } });
    },
  },
};
</script>

<style lang="less" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.btn-size {
  height: 50px;
  width: 160px;
  font-size: 18px;
}
.mar-auto {
  margin: 0 auto;
}
.bg_wrap {
  height: 550px;
  padding-top: 30px;
  background: url("../assets/image/ProductApartment/BI-banner.png") no-repeat
    center;
  background-size: cover;
  background-position: 100% 100%;
  h1 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 44px;
    font-weight: bold;
    padding-right: 750px;
  }
  h3 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 130px;
    padding-right: 75px;
    // font-weight: 600;
    color: #2267f6;
  }
  h4 {
    color: #fff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 20px;
    padding-right: 750px;
  }
  .btn_warp {
    width: 1200px;
    margin: 31px auto;
  }
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  .type-list {
    padding: 0 50px;
  }
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: #030d1e;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
  }
  .bottom_warp {
    padding-bottom: 35px;
  }

  .bi-platform {
    // margin-top: 124px;
    flex-wrap: nowrap;
    .bi-item {
      text-align: left;
      margin-bottom: 16px;
      font-size: 18px;
      color: #51545b;
    }
    .hint {
      height: 20px;
      widows: 20px;
      margin-right: 10px;
    }
    .flat {
      height: 464px;
      width: 693px;
    }
    .bi-pic1 {
      background-image: url("../assets/image/ProductApartment/BI-pic1.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .bi-pic2 {
      background-image: url("../assets/image/ProductApartment/BI-pic2.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .bi-pic3 {
      background-image: url("../assets/image/ProductApartment/BI-pic3.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    h3 {
      text-align: left;
      margin: 85px 0 12px;
      color: #000000;
      font-weight: 700;
      font-size: 28px;
    }
  }
  .martop-45 {
    margin-top: 45px;
  }
  .product_item {
    display: flex;
    padding: 42px 32px;
    border: 1px solid #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 56px;
      width: 56px;
      margin-right: 29px;
    }
    h4 {
      font-size: 24px;
      margin-bottom: 7px;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 16px;
      text-align: left;
    }
  }

  .board_warp {
    // margin:0 12px;
    .board_top {
      padding: 20px 0;
      color: #ffffff;
      h3 {
        color: #ffffff;
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .blue {
      background: #0077ff;
      box-shadow: 0px 7px 29px 0px #a4ccfa;
    }
    .green {
      background: #30c758;
      box-shadow: 0px 7px 29px 0px #a1ffba;
    }
    .orange {
      background: #ff7f0f;
      box-shadow: 0px 7px 29px 0px #fdc89a;
    }
    .subblue {
      background: #00ccff;
      box-shadow: 0px 7px 29px 0px #d8f7ff;
    }
    .board_bottom {
      height: 532px;
      margin: 40px 0 20px 0;
      background: #f1f5fb;
      border: 1px solid #fcfefd;
      box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
      padding: 0 26px;
      h4 {
        color: #3d3d3d;
        font-weight: 700;
        font-size: 20px;
        margin: 25px 0 10px 0;
      }
      .board_sbutile {
        margin-top: 10px;
        font-size: 16px;
        color: #ffffff;
        border: 1px solid #0b7eff;
        border-radius: 4px;
        padding: 5px 0;
        background: linear-gradient(270deg, #1181ff 0%, #6db9ff 100%);
      }
      .mt_35 {
        margin-top: 35px;
      }
      .label_warp {
        // height: 167px;
        border-radius: 4px;
        margin: 10px 0;

        padding: 10px 5px 10px 5px;
        border: 1px solid #dbe9ff;
        img {
          margin-top: 10px;
          width: 202px;
          height: 95px;
        }
      }
      .text_warp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 40px;
        font-size: 16px;
        font-weight: 400;
        span {
          margin-top: 5px;
        }
      }
    }
  }
}

// .tab_container {
//   width: 100%;
//   background: #f5f6fa;
//   .tab_warp {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: 1px solid #e4edfc;
//   }
//   .tab_item {
//     font-weight: 700;
//     font-size: 20px;
//     padding-bottom: 20px;

//     cursor: pointer;
//   }
//   .check {
//     position: relative;
//   }
//   .check::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 200px;
//     height: 4px;
//     background: #0060ff;
//   }
//   .content {
//     margin: 47px 0 70px 0;
//     display: flex;
//     img {
//       width: 742px;
//       height: 432px;
//       margin-left: 30px;
//     }
//     .cockpit_text {
//       margin-top: 36px;
//       text-align: left;
//       h3 {
//         color: #030d1e;
//         font-size: 20px;
//         font-weight: 700;
//         margin-bottom: 10px;
//       }
//       p {
//         font-weight: normal;
//         font-size: 16px;
//         line-height: 30px;
//       }
//     }
//   }
// }
.serve_warp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .serve_item {
    height: 240px;
    width: 281px;
    box-sizing: border-box;
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    font-weight: 400;
    padding: 48px;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.2);
    img {
      height: 72px;
      width: 72px;
    }
    h4 {
      margin-bottom: 5px;
      color: #0c1b35;
      font-size: 18px;
    }
    p {
      color: #808082;
    }
  }
}
.exper {
  margin: 0 auto;
  margin-top: 55px;
}
.bottom_bg {
  height: 554px;
  width: 100%;
  background: url("../assets/image/ProductApartment/bottom_bg.png") no-repeat
    center;
  background-size: cover;
  padding-top: 64px;
  text-align: center;
  .exhibition_warp {
    padding: 0 20px 40px 90px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .exhibition_item {
      text-align: center;
      h4 {
        color: #3d3d3d;
        font-size: 20px;
        margin: 10px 0;
      }
      img {
        height: 100px;
        width: 100px;
      }
      p {
        font-size: 16px;
        color: #545558;
      }
    }
  }
}
.mobile_solution {
  display: none;
}
@media screen and (max-width: 768px) {
  .btn-size {
    height: 29px;
    width: 72px;
    font-size: 12px;
    font-weight: 700;
  }
  .bg_wrap {
    height: 312px;
    background: url("../assets/image/ProductApartment/BI-banner-mobile.png")
      no-repeat center;
    background-size: cover;
    background-position: 100% 44px;
    padding-left: 12px;
    h3 {
      padding-top: 52px;
      font-size: 18px;
      width: 100%;
      padding-right: 50%;
    }
    h1 {
      color: #030d1e;
      font-size: 18px;
      width: 100%;
      padding-right: 50%;
    }
    h4 {
      color: #768396;
      padding-top: 8px;
      font-size: 11px;
      width: 100%;
      padding-right: 50%;
    }
    .btn_warp {
      width: 100%;
      margin: 20px 0 0;
    }
  }
  .mar-24 {
    margin: 0 24px;
  }
  .solution_part {
    width: 100%;
    padding-bottom: 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
      margin-bottom: 10px;
    }
    .bottom_warp {
      padding-bottom: 24px;
    }
    .product_item {
      margin: 0 12px;
      padding: 20px 24px;
      img {
        height: 48px;
        width: 48px;
        margin-right: 24px;
      }
      .item_left {
        h4 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .solution_part {
    .bi-platform {
      margin-top: 20px;
      margin-bottom: 67px;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: row-reverse;
      .bi-text-warp {
        margin: 0 12px;
      }
      .bi-item {
        font-size: 11px;
        .hint {
          height: 12px;
          width: 12px;
          margin-right: 6px;
        }
      }

      h3 {
        font-size: 14px;
        margin: 13px 0 8px;
      }
      .flat {
        width: 154px;
        height: 270px;
        // margin-right: 13px;
      }
      .bi-pic1 {
        background-image: url("../assets/image/ProductApartment/BI-mobile-pic1.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
      .bi-pic2 {
        background-image: url("../assets/image/ProductApartment/BI-mobile-pic2.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 10px;
      }
      .bi-pic3 {
        background-image: url("../assets/image/ProductApartment/BI-mobile-pic3.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .bi-platform:last-child {
      margin-bottom: 0;
    }
  }
  .mar-flanks-6 {
    margin: 0 6px !important;
  }
  .solution_part {
    .board_warp {
      .board_top {
        padding: 12px 0;
        h3 {
          font-size: 16px !important;
        }
        p {
          font-size: 12px !important;
        }
      }
      .board_bottom {
        margin: 12px 0 28px 0;
        padding: 0 10px;
        height: 426px;
        h4 {
          font-size: 14px;
          margin: 16px 0 12px 0;
        }
        .board_sbutile {
          font-size: 12px;
        }
        .label_warp {
          padding: 12px;
          p {
            font-size: 12px;
            color: #768396;
            margin-bottom: 8px;
          }
          img {
            height: 53px;
            width: 124px;
            margin-top: 8px;
          }
        }

        .text_warp {
          font-size: 12px;
          padding: 12px 16px;
        }
      }
    }
  }
  .serve_warp {
    margin: 0 12px;
    .serve_item {
      height: 150px;
      width: 45vw;
      margin-top: 12px;
      padding: 12px 18px;
      img {
        height: 40px;
        width: 40px;
      }
      h4 {
        font-size: 14px;
      }
      p {
        font-size: 11px;
      }
    }
  }
  .exper {
    margin-top: 24px;
  }
  .bottom_bg {
    height: 448px;
    width: 100%;
    background: url("../assets/image/ProductApartment/bottom_bg_mobile.png")
      no-repeat center;
    background-size: cover;
    padding-top: 0;
    text-align: center;
    .exhibition_warp {
      width: 100%;
      padding: 0 40px;
      flex-wrap: wrap;
      .exhibition_item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 52px;
        .exhibition_text {
        }
        img {
          height: 47px;
          width: 47px;
          margin-right: 16px;
        }
        h4 {
          font-size: 14px;
          text-align: left;
          font-weight: 600;
          margin: 0;
        }
        p {
          font-size: 12px;
          text-align: left;
        }
      }
    }
  }
}
</style>
