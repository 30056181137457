<template>
  <div class="tab_container">
    <div class="solution_part">
      <h2>{{ title }}</h2>
      <h4 class="mar-24">{{ subTitle }}</h4>
      <div class="tab_warp">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          :class="{ tab_item: true, check: current == index }"
          @click="current = index"
        >
          {{ item }}
        </div>
      </div>

      <div class="content" v-show="current == 0">
        <div class="cockpit_text">
          <h3>纵观全局，辅助决策</h3>
          <p>
            覆盖企业汇报、数据看板、指挥中心等场景，打造多维度、立体化移动管理驾驶舱，助力企业经营决策。
          </p>
          <h3>深层次数据下钻与分析</h3>
          <p>
            支持不限层次的数据钻取和多维度联动分析，协助用户更好地分析问题。
          </p>
          <h3>融合多种数据源，快速构建数据中心</h3>
          <p>
            满足各类数据分析需求，实现炫酷直观的演示效果。
          </p>
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button btn-size">立即体验</div>
      </a>
          
        </div>
        <div class="img-warp2">
          <img
            src="@/assets/image/ProductApartment/bitab-1.png"
            width="100%"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="content" v-show="current == 1">
        <div class="cockpit_text">
          <h3>业、财、稅一体化</h3>
          <p>
            打通财务、业务、税务及资金流转之间的壁垒，实现企业智能化财务管理
          </p>
          <h3>丰富的财务分析模型</h3>
          <p>
            资金分析、运营能力分析、盈利能力分析、偿债能力分析等财务分析，借助BI可帮助财务人员快捷应用各种分析模型。
          </p>
          <h3>自定义更新报表数据</h3>
          <p>
            通过设置数据报表更新任务，让报表数据按需自定义更新，也可手动更新。避免重复性的数据拼接、汇总等繁琐工作。
          </p>
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button btn-size">立即体验</div>
      </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/bitab-2.png" alt="" />
        </div>
      </div>
      <div class="content" v-show="current == 2">
        <div class="cockpit_text">
          <h3>提高市场营销和销售效率</h3>
          <p>
            企业可以更好地了解客户需求和市场趋势，制定更精确的销售计划和营销策略，提高市场营销和销售效率。
          </p>
          <h3>培养数据驱动业务决策</h3>
          <p>
            以准确、高效的方式为员工提供数据分析服务，促进科学决策和公司的可持续发展。
          </p>
          <h3>提高客户满意度和忠诚度</h3>
          <p>
            通过BI工具，企业可以更好地了解客户需求，提供更准确的产品和服务，从而提高客户满意度和忠诚度。
          </p>
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button btn-size">立即体验</div>
      </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/bitab-3.png" alt="" />
        </div>
      </div>
      <div class="content" v-show="current == 3">
        <div class="cockpit_text">
          <h3>拖拉拽式快速制作BI报表</h3>
          <p>不用下载安装，直接通过浏览器打开就能用，通过拖拉拽方式快速搭建可视化分析报表。</p>
          <h3>由“表”及里，实现数据智能</h3>
          <p>
            以“AI平民化”为理念，集成多种算法，可全流程可视化建模，降低AI应用门槛，驱动业务增长。
          </p>
          <h3>丰富的图表展现，让人眼前一亮</h3>
          <p>
            精美的可视化展示，20多种图表形式，精心搭配的配色方案，找到最符合逻辑的表现方式。
          </p>
          <a href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s" target="_blank">
            <div class="button btn-size">立即体验</div>
      </a>
        </div>
        <div class="img-warp">
          <img src="../../assets/image/ProductApartment/bitab-4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "solution",
  props: {
    title: String,
    subTitle: String,
  },
  data() {
    return {
      current: 0,
      tabList: [
        "公司经营管理",
        "财务智能核算",
        "销售运营闭环",
        "自助分析新体验",
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.mar-24 {
  margin: 0 24px;
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 36px;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
  }
}
.button {
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.btn-size {
  width: 160px;
  height: 48px;
  font-size: 16px;
  margin: 24px 0;
}
.tab_container {
  width: 100%;
  .tab_warp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4edfc;
  }
  .tab_item {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 20px;

    cursor: pointer;
    width: 200px;
  }
  .check {
    position: relative;
  }
  .check::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 4px;
    background: #0060ff;
  }

  .content {
    margin: 47px 0 70px 0;
    display: flex;
    .img-warp2{
      width: 662px;
      margin-left: 30px;
      flex-shrink: 0;
    }
    .img-warp {
      width: 662px;
      // height: 432px;
      margin-left: 30px;
      flex-shrink: 0;
      position: relative;
      img {
        width: 100%;
      }
    }

    .cockpit_text {
      // margin-top: 12px;
      text-align: left;
      h3 {
        color: #030d1e;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
      }
    }
    .img-2-1 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 600px;
    }
    .img-2-2 {
      position: absolute;
      left: 0;
      top: 0;
      width: 600px;
    }
  }
}
@media screen and (max-width: 768px) {
  .solution_part {
    width: 100%;
    padding-bottom: 0;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
    }
  }

  .tab_container {
    .tab_warp {
      justify-content: space-evenly;
      flex-wrap: wrap;
      .tab_item {
        width: 128px;
        font-size: 12px;
        margin-top: 12px;
      }
      .check::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: #0060ff;
      }
    }
    .content {
      margin: 14px 12px;
      display: block;
      .btn-size {
        height: 29px;
        width: 72px;
        font-size: 12px;
        margin: 24px auto;
      }
      .cockpit_text {
        h3 {
          font-size: 14px;
          text-align: center;
        }
        p {
          font-size: 12px;
          text-align: center;
          line-height: 1.5;
        }
      }
      .img-warp2{
        // height: 216px;
        width: 350px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;
        img {
          width: 100%;
        }
      }
      .img-warp {
        height: 216px;
        width: 350px;
        max-width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
